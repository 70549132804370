import * as React from 'react';
import { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
// import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider, makeStyles} from '@mui/material/styles';
import { colors } from '@mui/material';
import bgi from './media/logo_bg.png'

import balancedtreetn from './media/balancedtreetn.png'
import implementqustn from './media/implementqustn.png'
import maximumsubarraytn from './media/maximumsubarraytn.png'
import minstacktn from './media/minstacktn.png'
import twosumbftn from './media/twosumbftn.png'
import twosumoptimaltn from './media/twosumoptimaltn.png'
import validanagramtn from './media/validanagramtn.png'
import validatebsttn from './media/validatebsttn.png'
import validparentn from './media/validparentn.png'



function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        STEMViz 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];
// const cards = [balancedtreetn, implementqustn, 
//   maximumsubarraytn, minstacktn, twosumbftn, twosumoptimaltn, 
//   validanagramtn, validatebsttn, validparentn];
  
const cards = [twosumbftn, twosumoptimaltn, validanagramtn, 
  implementqustn, validparentn, minstacktn,
  balancedtreetn, validatebsttn, maximumsubarraytn];

const videoLinks = ['https://youtu.be/ml0jM-xlXW0', 'https://youtu.be/9BLYmC-WD0g',
'https://youtu.be/sg7knNHv6IA', 'https://youtu.be/xxytjNGFsdQ', 'https://youtu.be/-w37Z1sAXw8',
'https://youtu.be/9tGoZdUmGHU', 'https://youtu.be/WDO72_Dldm0', 'https://youtu.be/9PliPCjkugk', 
'https://youtu.be/Skb35Q5b2l8']


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const cardTitles = ['LC Q1: Two Sum Brute Force', 
  'LC Q1: Two Sum Optimal',
  'LC Q21: Valid Anagram', 
  'LC Q242: Implement Queue Using Stacks', 
  'LC Q20: Valid Parentheses', 
  'LC Q155: Min Stack',
  'LC Q110: Balanced Binary Tree', 
  'LC Q98: Validate Binary Search Tree', 
  'LC Q53: Maximum Subarray']

  const cardDescriptions = [['nested for loops, brute force implementation, space & time complexity'], 
  ['optimal hashmap/dictionary implementation, working and storing data in dictionaries, space & time complexity'], 
  ['optimal hashmap/dictionary implementation, frequency counter pattern'], 
  ['queue operations (queue, enqueue), stack operations (push, pop)'], 
  ['stack operations (push, pop, peek, isEmpty), retrieving values in constant time'], 
  ['stack operations (push, pop), working with strings, conditional statements and edge cases'], 
  ['recursion, depth first search, post order traversal, bottom up approach, helper functions'], 
  ['recursion, adding extra recursive parameters, depth first search, post order traversal'], 
  ['Kadane’s algorithm, greedy algorithm, dynamic programming, contiguous subarray patterns']]






export default function Album() {

  const [isDSButtonContained, setIsDSButtonContained] = useState('outlined');
  const [isSQLButtonContained, setIsSQLButtonContained] = useState('outlined');
  const [isWebDevButtonContained, setIsWebDevButtonContained] = useState('outlined');
  
  const handleClick1 = () => {
    // Toggle between 'contained' and 'outlined' variants on each click
    setIsDSButtonContained((prevVariant) => (prevVariant === 'outlined' ? 'contained' : 'outlined'));
    setIsSQLButtonContained((prevVariant) => (prevVariant === 'contained' ? 'outlined' : 'contained'));
    setIsSQLButtonContained((prevVariant) => (prevVariant === 'outlinied' ? 'outlined' : 'outlined'));

    // setIsWebDevButtonContained((prevVariant) => (prevVariant === 'contained' ? 'outlined' : 'contained'));

  };

  const handleClick2 = () => {
    // Toggle between 'contained' and 'outlined' variants on each click
    setIsSQLButtonContained((prevVariant) => (prevVariant === 'outlined' ? 'contained' : 'outlined'));
    setIsDSButtonContained((prevVariant) => (prevVariant === 'contained' ? 'outlined' : 'contained'));
    setIsDSButtonContained((prevVariant) => (prevVariant === 'outlined' ? 'outlined' : 'outlined'));

  };

  // const handleClick3 = () => {
  //   // Toggle between 'contained' and 'outlined' variants on each click
  //   // setIsWebDevButtonContained((prevVariant) => (prevVariant === 'outlined' ? 'contained' : 'outlined'));
  //   // setIsDSButtonContained((prevVariant) => (prevVariant === 'contained' ? 'outlined' : 'contained'));
  //   // setIsSQLButtonContained((prevVariant) => (prevVariant === 'contained' ? 'outlined' : 'contained'));
  // };
 

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          {/* <CameraIcon sx={{ mr: 2 }} /> */}
          <Typography variant="h6" color="inherit" noWrap>
            STEMViz Video Gallery
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            // backgroundImage: `url(${bgi})`,

            // bgcolor: 'background.paper',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            pt: 1,
            pb: 1,
           
          }}
        >
          <Container maxWidth="sm" 
          sx={{backgroundImage:{bgi}, 
          }}>
            {/* <img src={bgi} style={{objectFit: 'contain', zIndex:"-1"}}></img> */}
            <Typography
              component="h1"
              variant="h1"
              align="center"
              color="text.primary"
              fontFamily={"Computer Modern"}
              fontStyle={'normal'}
              gutterBottom
              mt={10}
            >
              STEMViz 
            </Typography>
            <Typography fontSize='20px' fontStyle={'italic'} textAlign={'center'}>
              A picture is worth a thousand words. Imagine how much you can learn from a 2 minute video. 
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              
              {/* todo: add implementation for both buttons */}
              <Button variant={isDSButtonContained} onClick={handleClick1}>Data Structure Videos</Button>
               <Button variant={isSQLButtonContained} onClick={handleClick2}>SQL Videos... Coming Soon</Button>
              {/* // <Button variant={isWebDevButtonContained} onClick={handleClick3}>Web Dev Videos</Button> */} 
              

              {/* <Button variant={isRightButtonContained} onClick={handleClick2}>Finance Videos</Button> */}
            </Stack>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', 
                  flexDirection: 'column',
                  transition: 'transform 0.1s',
                  borderRadius: "10px",
                  '&:hover': {
                    transform: 'scale(1.08)',
                    // bgcolor: '#092635'
                  } 
                }}
                elevation={10}
                >
                  {/* {cardPics.map((cpics) => ( */}
                  <CardMedia
                    component="div"
                    image={card}
                    sx={{
                      // 16:9
                      pt: '96.25%',
                 
                      objectFit: 'cover'
                    }}
                  />
                  {/* {cardTitles.map((ctitles, cindex) => ( */}
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {cardTitles[index]}
                    </Typography>
                    <Typography fontWeight={"bold"}>
                    Concepts mentioned
                    </Typography>
                    <Typography fontFamily={'Computer Modern'}>
                      {cardDescriptions[index]}
                    </Typography>
                  </CardContent>
                  {/* ))} */}
                  <CardActions>
                    <a href={videoLinks[index]} target="_blank" rel="noopener noreferrer">

                    <Button size="small">Go to video</Button>
                    </a>
                    {/* todo: change edit button and add somethign else */}
                    {/* <Button size="small">Edit</Button> */}
                  </CardActions>

                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          {/* Footer */}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          {/* New videos drop every week, make sure to check us out at STEMViz! */}
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}